import {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback
} from "react";

import { useNavigate, useLocation } from "react-router-dom";
import isEqual from "lodash/isEqual";
import { getInvoicesWithProjectId, getSortedOrdersWithProjectId } from "utils";

import {
  projectsByCompanyId,
  getOnCompanyID,
  ordersByCompanyId,
  allContacts,
  invoicesByCompanyId,
  login,
  logout,
  getDocuments,
  verifyToken,
  getSignaturesOnProjectId,
  notificationsByCompanyId
} from "../api";

export const APIContext = createContext({});

// const USER_OBJECT = {
//   AuthApproved: true,
//   MFAChallengeRequired: true,
//   MFAmissing: false,
//   FirstName: "Pär",
//   LastName: "Thunberg",
//   Email: "par@accomplice.se",
//   Mobile: "+46704850645",
//   CompanyID: "12228",
//   token: "test_token",
//   tokenExpiresIn: 1665392194
// };

const ApiContextProvider = (props) => {
  // console.count("ApiContextProvider Render count");

  const { children } = props;

  const [projects, setProjects] = useState([]);
  const [orders, setOrders] = useState([]);
  const [contacts, setContacts] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [company, setCompany] = useState("");
  const [companySalesPerson, setCompanySalesPerson] = useState("");
  const [user, setUser] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [docsLoading, setDocsLoading] = useState(false);

  let navigate = useNavigate();
  const location = useLocation();
  // verifies jwt token and logout user or sets user object
  useEffect(() => {
    setIsLoadingUser(true);
    const asyncFunc = async () => {
      const mtabUser = JSON.parse(localStorage.getItem("mtabUser"));

      if (mtabUser) {
        // verify token
        const res = await verifyToken(mtabUser?.token);

        // console.log("res", res);

        if (res?.message === "success") {
          setUser(mtabUser);
          setIsVerified(true);
        } else {
          logoutUser();
        }
      } else {
        // Check if
        logoutUser();
      }
    };

    // run
    asyncFunc();
    setIsLoadingUser(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set companyId
  useEffect(() => {
    if (user?.CompanyID && user?.token) {
      setCompanyId(user.CompanyID);
    }
  }, [user]);

  // sets projects and orders
  useEffect(() => {
    if (companyId && user?.token && isVerified) {
      const fetchData = async () => {
        try {
          setIsLoadingUser(true);

          const companyDataPromise = getOnCompanyID(companyId, user?.token);
          const projectsDataPromise = projectsByCompanyId(
            companyId,
            user?.token
          );
          const ordersDataPromise = ordersByCompanyId(companyId, user?.token);

          const contactsDataPromise = allContacts(user?.token);
          const invoicesDataPromise = invoicesByCompanyId(
            companyId,
            user?.token
          );
          const notificationsDataPromise = notificationsByCompanyId(
            companyId,
            user?.token
          );

          const [
            { value: projectsData },
            { value: ordersData },
            { value: contactsData },
            { value: invoicesData },
            { value: companyData },
            { value: notificationsData }
          ] = await Promise.allSettled([
            projectsDataPromise,
            ordersDataPromise,
            contactsDataPromise,
            invoicesDataPromise,
            companyDataPromise,
            notificationsDataPromise
          ]);

          // computing filteredProjects
          const filteredProjects = projectsData?.map((project) => {
            const { ProjectID, ID } = project;

            const projectOrdersSorted = getSortedOrdersWithProjectId(
              ordersData,
              ProjectID
            );

            const projectInvoicesData = getInvoicesWithProjectId(
              invoicesData,
              ProjectID
            );

            return {
              ...project,
              id: ID,
              orders: projectOrdersSorted,
              invoices: projectInvoicesData
            };
          });

          setOrders(ordersData);
          setContacts(contactsData);
          setCompany(companyData?.[0]);
          setInvoices(invoicesData);
          setProjects(filteredProjects);
          setNotifications(notificationsData);

          setIsLoadingUser(false);
        } catch (error) {
          console.error("error context fetching all data", error);
          setIsLoadingUser(false);
        }
      };

      fetchData();
    }
  }, [companyId, isVerified, user?.token]);

  // set companySalesPerson
  useEffect(() => {
    if (contacts) {
      const [salesPerson] = contacts.filter((item) => {
        const { Userid } = item;
        return Userid === company?.SalesPersonID;
      });
      setCompanySalesPerson(salesPerson);
    }
  }, [company?.SalesPersonID, contacts]);

  // first login
  /**
   *
   * @param {string} userid
   * @param {string} password
   * @returns void
   */
  const loginUser = async (userid, password) => {
    const data = await login({ userid, password }, setErrorMessage);

    if (data) {
      // this has token
      localStorage.setItem("mtabUser", JSON.stringify(data));

      setUser(data);
      setErrorMessage("");
    }
  };
  /**
   *
   * @param {string} userid
   * @param {string} password
   * @returns void
   */
  const verifyUser = async (data) => {
    if (data) {
      // this has token
      const { token, tokenExpiresIn } = data;
      const userWithToken = { ...user, token, tokenExpiresIn };
      setUser(userWithToken);
      localStorage.setItem("mtabUser", JSON.stringify(userWithToken));
      setErrorMessage("");
    }
  };

  /**
   *
   * @param {*} projectId
   * @returns
   */
  const getOrdersByProjectId = useCallback(
    (projectId) => {
      const projectOrdersSorted = getSortedOrdersWithProjectId(
        orders,
        projectId
      );

      return projectOrdersSorted;
    },
    [orders]
  );

  /**
   *
   * @param {*} projectId
   * @returns
   */
  const getProjectByProjectId = useCallback(
    (projectId) => {
      return projects?.find((item) => item.ProjectID === parseInt(projectId));
    },
    [projects]
  );

  /**
   *
   * @param {*} status
   * @returns
   */
  const getProjectsByStatus = useCallback(
    (status) => {
      const statusArray = Array.isArray(status) ? status : [status];
      return projects?.filter(
        (projects) =>
          statusArray.indexOf(projects?.ProjectStatus?.[0]?.StatusNo) > -1
      );
    },
    [projects]
  );

  /**
   * Fetches latest notifications used for polling
   */

  const getNotifications = async () => {
    if (companyId && user?.token && isVerified) {
      const notificationsData = await notificationsByCompanyId(
        companyId,
        user?.token
      );

      if (!isEqual(notifications, notificationsData)) {
        console.log("found " + notificationsData?.length + " notifications.");
        setNotifications(notificationsData);
      }
      // console.log("no-new notifications.");
    }
    return true;
  };

  /**
   *
   * @param {Array} orders
   * @param {string} projectId
   * @returns
   */
  const documents = useCallback(
    async (orders = [], projectId = "") => {
      setDocsLoading(true);

      const files = await getDocuments(
        orders,
        projectId,
        companyId,
        user?.token
      );

      // TODO: enable this
      const newDocs = await getSignaturesOnProjectId(projectId, user?.token);

      // project that has docs 911580
      // TODO: remove text this
      // const newDocs = await getSignaturesOnProjectId(911580, user?.token);
      const combinedDocs = [...files, ...newDocs];

      setDocsLoading(false);
      return combinedDocs;
    },
    [companyId, user?.token]
  );

  /**
   *
   * @param {*} orderId
   * @returns
   */
  const getOrderByOrderId = useCallback(
    (orderId) => {
      const foundOrder = orders?.find(
        (order) => order.OrderID === parseInt(orderId)
      );

      if (!foundOrder) {
        return null;
      }

      return foundOrder;
    },
    [orders]
  );

  /**
   *
   * @param {*} projectId
   * @returns
   */
  const getInvoicesByProjectId = useCallback(
    (projectId) => {
      // get Invoices for projects projectId
      const projectInvoicesData = getInvoicesWithProjectId(invoices, projectId);

      return projectInvoicesData;
    },
    [invoices]
  );

  const logoutUser = useCallback(
    (checkPath = true) => {
      localStorage.removeItem("mtabUser");
      localStorage.removeItem("token");

      setUser({});
      setIsVerified(false);
      setIsLoadingUser(false);

      if (user?.token) {
        logout(user?.token);
      }
      // TODO: Check if we are in a 'free' path (update/set password route)
      // console.log("location", location);
      if (
        location.pathname.indexOf("/account/set-password") >= 0 &&
        checkPath === true
      ) {
        navigate(location);
      } else {
        navigate("/login");
      }
    },
    [navigate, user?.token]
  );

  return (
    <APIContext.Provider
      value={{
        projects,
        companySalesPerson,
        company,
        orders,
        invoices,
        isVerified,
        notifications,
        user,
        errorMessage,
        isLoadingUser,
        docsLoading,
        getOrdersByProjectId,
        getProjectByProjectId,
        getProjectsByStatus,
        getOrderByOrderId,
        getNotifications,
        getInvoicesByProjectId,
        loginUser,
        verifyUser,
        logoutUser,
        setIsVerified,
        documents
      }}
    >
      {children}
    </APIContext.Provider>
  );
};

/**
 *
 * @returns {{docsLoading: boolean , projects: Array, companySalesPerson, orders: Array, invoices: Array, invoiceFiles, isVerified: boolean, user:{}, errorMessage: '', isLoadingUser: boolean, getOrdersByProjectId:[], getProjectByProjectId:[], getProjectsByStatus, getOrderByOrderId, getInvoicesByProjectId, loginUser, logoutUser, setIsVerified, documents, getInvoicesByOrderId, company }}
 */
export function useApi() {
  const context = useContext(APIContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}

export default ApiContextProvider;
