import { useEffect, useState } from "react";
import ProjectCard from "../ProjectCard/ProjectCard";
import styles from "./List.module.scss";
import { getOrderDates, getProjectStatus, getOrderType } from "../../utils";

import { REACT_APP_PAGE_SIZE } from "../../config";

const PAGE_SIZE = REACT_APP_PAGE_SIZE || 3;

const List = (props) => {
  const { projectList = [], title, sort, isVisible } = props;
  const [noOfItems, setNoOfItems] = useState(PAGE_SIZE);
  const [showBtn, setShowBtn] = useState(true);
  const [sortedProjectList, setSortedProjectList] = useState([]);

  useEffect(() => {
    // Reset page size after toggling filters
    setNoOfItems(PAGE_SIZE);
  }, [isVisible]);

  useEffect(() => {
    let noOfItemsSession = sessionStorage.getItem("noOfItems" + title);
    if (noOfItemsSession) {
      setNoOfItems(+noOfItemsSession);
    }
  }, []);

  useEffect(() => {
    setSortedProjectList(projectList);

    const sortedList = [...projectList].map((a) => ({
      ...a,
      lastOrderDate: findLastOrderDate(a),
      lastOrderStartDate: findLastOrderStartDate(a)
    }));

    sortedList.sort((a, b) => {
      if (sort === "asc") {
        return a.lastOrderDate - b.lastOrderDate;
      }
      if (sort === "desc") {
        return b.lastOrderStartDate - a.lastOrderStartDate;
      }
    });
    setSortedProjectList(sortedList);
  }, [projectList, sort]);

  const loadMore = () => {
    setNoOfItems(noOfItems + PAGE_SIZE);
    sessionStorage.setItem("noOfItems" + title, noOfItems + PAGE_SIZE);
    if (projectList?.length > PAGE_SIZE) {
      setShowBtn(true);

      if (projectList?.length >= noOfItems) {
        setShowBtn(true);
      }
    } else {
      setShowBtn(false);
    }
  };

  const findLastOrderDate = (project) => {
    const lastOrder = project?.orders?.at(-1);

    // console.log(getOrderDates(lastOrder).endDate);
    return getOrderDates(lastOrder).endDate;
  };

  const findLastOrderStartDate = (project) => {
    const lastOrder = project?.orders?.at(0);

    // console.log(getOrderDates(lastOrder).endDate);
    return getOrderDates(lastOrder).startDate;
  };

  const findLastOrderType = (project) => {
    const lastOrder = project?.orders?.at(-1);
    const orderType = getOrderType(lastOrder);
    if (orderType === undefined) return "";
    return orderType;
  };

  return (
    <>
      {isVisible && (
        <>
          <h2>
            {projectList?.length} {title}
          </h2>
          <ul className={sort ? `${styles.asc}` : `${styles.desc}`}>
            {sortedProjectList?.length === 0 && (
              <li className={styles.empty}>
                You have no {title.toLowerCase()} projects.
              </li>
            )}
            {sortedProjectList?.slice(0, noOfItems)?.map((project, i) => {
              return (
                <li key={project.ProjectID}>
                  <ProjectCard
                    list={title}
                    title={project.Header}
                    id={project.ProjectID}
                    ongoingOrderType={findLastOrderType(project)}
                    endDate={project.lastOrderDate}
                    startDate={project.lastOrderStartDate}
                    newDate={""}
                    sort={sort}
                    link={"/project/" + project.ProjectID}
                    lng={project.ProjectID / 450000 + 16.25328}
                    lat={project.ProjectID / 450000 + 57.454591}
                    showStatusBar={true}
                    status={getProjectStatus(project)}
                  />
                </li>
              );
            })}
          </ul>
          {showBtn && projectList?.length > noOfItems ? (
            <button
              type="button"
              onClick={loadMore}
              className={styles.loadMore}
            >
              Show more
            </button>
          ) : null}
        </>
      )}
    </>
  );
};

export default List;
