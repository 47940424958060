import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import logo from "../../assets/images/mtab-logo.svg";
import { User } from "../../assets/icons";

import { Notifications, ApiNotifications } from "../";
import styles from "./Header.module.scss";
import { useApi } from "context/context";

//ersätt listan och objektet nedan med motsvarande från context
const notifyList = [
  {
    id: 1,
    title: "Projekt 1",
    endDate: "13:30, Sun 17 Jan 2022"
  },
  {
    id: 2,
    title: "Projekt 2",
    endDate: "09:30, Sun 9 Jan 2022"
  }
];

const status = {
  id: 1,
  text: "Delayed"
};

const Header = () => {
  const { isVerified, user } = useApi();
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  // TODO: revisit this logic (it's the logic to create hamburger menu on mobile)
  const toggleMenu = () => {
    let menu = document.querySelector("#tool-menu");
    let spans = menu.querySelectorAll("button > span");

    if (menu.classList.contains(`${styles.open}`)) {
      menu.classList.remove(`${styles.open}`);
    } else {
      menu.classList.add(`${styles.open}`);
    }
    for (let i = 0; i < spans?.length; i++) {
      if (spans[i].classList.contains(`${styles.change}`)) {
        spans[i].classList.remove(`${styles.change}`);
      } else {
        spans[i].classList.add(`${styles.change}`);
      }
    }
  };

  return (
    <header>
      <img src={logo} className={styles.logo} alt="logo" />
      <nav>
        {isVerified && (
          <ul className={styles.mainMenu}>
            <li>
              <Link
                to={"/"}
                className={
                  !location?.pathname?.includes("/invoices")
                    ? styles.active
                    : styles.notActive
                }
              >
                {t("projects")}
              </Link>
            </li>
            <li>
              <Link
                to={"/invoices"}
                className={
                  location?.pathname?.includes("/invoices")
                    ? styles.active
                    : styles.notActive
                }
              >
                {t("invoices")}
              </Link>
            </li>
          </ul>
        )}
        <button className={styles.burger} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <ul className={styles.toolMenu} id="tool-menu">
          <ApiNotifications isUserVerified={isVerified} />
          {/* <Notifications
            notifyList={notifyList}
            status={status}
            isUserVerified={isVerified}
            setNotifications={setNotifications}
          /> */}
          {isVerified && (
            <li onClick={() => navigate("/profile")}>
              <User className={styles.user} />
              {user?.FirstName}
            </li>
          )}
          {/* TODO: Temporarily hiding the language */}
          {/* <li className={styles.language}>
            {i18n.language}
            <Arrow className={styles.arrow} />
            <LanguageDropdown changeLanguage={i18n.changeLanguage} />
          </li> */}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
