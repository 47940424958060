import { Routes } from "react-router-dom";
import { useApi } from "../../context/context";

function PrivateRoute({ children }) {
  const { user } = useApi();

  if (user.token) {
    return <Routes>{children}</Routes>;
  }
  return null;
}

export default PrivateRoute;
