import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ProjectsList.module.scss";

import List from "../List/List";
import FilterList from "../FilterList/FilterList";
import SearchProject from "../SearchProject/SearchProject";

const filterControls = [
  { label: "Ongoing", status: false, id: "0" },
  { label: "Upcoming", status: false, id: "1" },
  { label: "Completed", status: false, id: "2" }
];

const sorting = [
  { label: "Project end", value: "asc" },
  { label: "Project start", value: "desc" }
];

const ProjectsList = ({ projects = [] }) => {
  const [filterControl, setFilterControl] = useState(filterControls);
  const [ongoing, setOngoing] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [optionState, setOptionState] = useState("asc");
  const [opacityClass, setOpacityClass] = useState("noOpacity");
  const [inputFocus, setInputFocus] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [noSearchResult, setNoSearchResult] = useState("");
  const { t } = useTranslation();
  const [run, setRun] = useState(false);

  useEffect(() => {
    if (run) {
      sessionStorage.setItem("filterControls", JSON.stringify(filterControl));
      sessionStorage.setItem("optionState", optionState);
      sessionStorage.setItem("searchValue", searchValue);
    } else {
      setRun(true);
    }
  }, [optionState, filterControl, searchValue]);

  useEffect(() => {
    const filterControlsLocal = JSON.parse(
      sessionStorage.getItem("filterControls")
    );
    const optionStateLocal = sessionStorage.getItem("optionState");
    const searchValueLocal = sessionStorage.getItem("searchValue");
    if (filterControlsLocal) {
      setFilterControl(filterControlsLocal);
    }
    if (optionStateLocal) {
      setOptionState(optionStateLocal);
    }
    if (searchValueLocal) {
      setSearchValue(searchValueLocal);
    }
  }, []);

  useEffect(() => {
    if (projects.length > 0) {
      let filteredProjects = [...projects];

      if (searchValue !== "") {
        filteredProjects = [...projects]?.filter(
          (project) =>
            project?.Header?.toLowerCase()?.includes(
              searchValue?.toLowerCase()
            ) || project?.ProjectID?.toString()?.includes(searchValue)
        );

        if (filteredProjects?.length === 0) {
          setNoSearchResult(`${t("search.no_result")}`);
        } else {
          setNoSearchResult("");
        }
      } else {
        setNoSearchResult("");
      }

      const filteredListStarted = filteredProjects?.filter(
        (project) =>
          project.ProjectStatus[0]?.StatusNo >= 20 &&
          project.ProjectStatus[0]?.StatusNo <= 29
      );

      if (filteredListStarted?.length === 0) {
        filterControl[0].status = false;
      } else filterControl[0].status = true;

      setOngoing(filteredListStarted);

      const filteredListComing = filteredProjects?.filter(
        (project) => project.ProjectStatus[0]?.StatusNo < 20
      );

      if (filteredListComing?.length === 0) {
        filterControl[1].status = false;
      } else filterControl[1].status = true;

      setUpcoming(filteredListComing);

      const filteredListCompleted = filteredProjects?.filter(
        (project) => project.ProjectStatus[0]?.StatusNo >= 30
      );

      if (
        filteredListComing?.length === 0 &&
        filteredListStarted?.length === 0
      ) {
        filterControl[2].status = true;
      } else filterControl[2].status = false;

      setCompleted(filteredListCompleted);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, searchValue]);

  useEffect(() => {
    if (inputFocus === true) {
      setOpacityClass("opacity");
    } else {
      setOpacityClass("noOpacity");
    }
  }, [inputFocus]);

  const handleSort = (e) => {
    setOptionState(e.target.value);
  };

  return (
    <div>
      <div className={styles.row}>
        <FilterList
          setFilterControl={setFilterControl}
          filterControl={filterControl}
          opacity={opacityClass}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          resultLengths={[ongoing?.length, upcoming?.length, completed?.length]}
        />
        <SearchProject
          setInputFocus={setInputFocus}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          setOpacityClass={setOpacityClass}
        />
      </div>
      <div className={styles.row}>
        <form className={styles.sortDates}>
          <select
            name="sort"
            id="sort_dates"
            value={optionState}
            onChange={handleSort}
          >
            <>
              {sorting?.map((sort) => {
                return (
                  <option key={sort.value} value={sort.value}>
                    {sort.label}
                  </option>
                );
              })}
            </>
          </select>
        </form>
      </div>

      <section className={`${styles.container} ${styles[opacityClass]}`}>
        {noSearchResult === "" ? (
          <>
            <List
              projectList={ongoing}
              title="Ongoing"
              filterControl={filterControl[0]}
              sort={optionState}
              isVisible={filterControl[0].status}
            />
            <List
              projectList={upcoming}
              title="Upcoming"
              filterControl={filterControl[1]}
              sort={optionState}
              isVisible={filterControl[1].status}
            />
            <List
              projectList={completed}
              title="Complete"
              filterControl={filterControl[2]}
              sort={optionState}
              isVisible={filterControl[2].status}
            />
          </>
        ) : (
          <h2 className={styles.noResult}>{noSearchResult}</h2>
        )}
      </section>
    </div>
  );
};

export default ProjectsList;
