import StatusBar from "../StatusBar/StatusBar";
import { useTranslation } from "react-i18next";
import styles from "./OrderDetailsInfo.module.scss";

const OrderDetailsInfo = ({
  orderType = "-",
  startDate = "10 Jan 2022",
  endDate = "10 Jan 2022",
  orderStatus = { statusText: "" }
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.orderDetailsInfo}>
      <div>
        <p>{t("order")}</p>
        <p>{orderType}</p>
      </div>
      <div>
        <p>{t("start")}</p>
        <p>{startDate}</p>
      </div>
      <div>
        <p>{t("end")}</p>
        <p>{endDate}</p>
      </div>
      <StatusBar showStatus={true} statusText={orderStatus?.statusText} />
    </div>
  );
};

export default OrderDetailsInfo;
