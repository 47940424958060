import { createContext, useContext } from "react";
import cipDateFormat from "../utils/cipDateFormat";

export const LocalContext = createContext();

/**
 * Used for the temporary local storage notifications system
 *
 * @param {*} param
 * @returns
 */

const LocalContextProvider = ({ children }) => {
  // console.count("LocalContextProvider Render count");

  const saveCompany = (LOCAL_KEY, data) => {
    const savedToLocalStorage = cipDateFormat(new Date());
    // console.log("getting from storage", LOCAL_KEY);
    return window.localStorage.setItem(
      LOCAL_KEY,
      JSON.stringify({ ...data, savedToLocalStorage })
    );
  };
  const getCompany = (LOCAL_KEY) => {
    return JSON.parse(window.localStorage.getItem(LOCAL_KEY));
  };

  return (
    <LocalContext.Provider
      value={{
        saveCompany,
        getCompany
      }}
    >
      {children}
    </LocalContext.Provider>
  );
};

export function useLocal() {
  const context = useContext(LocalContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}

export default LocalContextProvider;
