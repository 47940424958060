import { useApi } from "../../context/context";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Loader from "../Loader/Loader";

import styles from "./Layout.module.scss";

/**
 *
 * @param {{ children, [className] }} param0
 * @returns React element
 */
const Layout = ({ children, className }) => {
  const { isVerified, isLoadingUser } = useApi();

  return (
    <div className={styles.layoutContainer}>
      <Header />
      <div className={`${styles.pageWrapper}`}>
        {isLoadingUser ? <Loader /> : <>{children}</>}
      </div>
      <Footer isUserVerified={isVerified} />
    </div>
  );
};

export default Layout;
