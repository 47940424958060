const Clock = (props) => {
  return (
    <svg
      width={15}
      height={15}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M12.5 8.234V5.747a5.233 5.233 0 1 0-10.466 0v2.487L1 11.288h12.534L12.5 8.234ZM5.506 12.48c0 .985.798 1.783 1.783 1.783h.09c.984 0 1.782-.798 1.782-1.783"
        stroke="#6E7699"
      />
    </svg>
  );
};

const Spinner = () => {
  return (
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

const TimeFlag = (props) => {
  return (
    <svg
      alt="Booked time"
      title="Booked time"
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      viewBox="0 0 312.43 235.21"
    >
      <path
        d="M-195.14 154.6l11.2-11.19a5.63 5.63 0 000-8 5.63 5.63 0 00-7.95 0l-11.2 11.2a103.66 103.66 0 00-63.84-26.43v-14.35a5.63 5.63 0 00-.33-1.63h18.59a5.6 5.6 0 005.6-5.6 5.6 5.6 0 00-5.6-5.6h-48a5.6 5.6 0 00-5.59 5.6 5.59 5.59 0 005.59 5.6H-278a5.31 5.31 0 00-.33 1.63v14.44A104.07 104.07 0 00-357.41 164a104.05 104.05 0 00-15.06 89.07 104 104 0 0060.27 67.28 104.06 104.06 0 0090.2-5.22 104.07 104.07 0 0052.11-73.79 104.07 104.07 0 00-25.24-86.74zm-11.93 135.18A92.84 92.84 0 01-272.76 317a92.86 92.86 0 01-65.68-27.22 92.91 92.91 0 01-27.2-65.68 92.93 92.93 0 0127.2-65.69 93 93 0 0165.68-27.22 92.93 92.93 0 0165.69 27.19 93.07 93.07 0 0127.17 65.72 93 93 0 01-27.17 65.71z"
        transform="translate(480.9 -93)"
        stroke="#6E7699"
        fill="#6E7699"
      ></path>
      <path
        d="M-233.11 177L-267 210.61a15 15 0 00-8.41-.77 15 15 0 00-7.49 3.9A14.7 14.7 0 00-287 224a14.76 14.76 0 004.27 10.24 14.68 14.68 0 0010.21 4.33 14.29 14.29 0 0010.31-4.32 14.54 14.54 0 004.21-10.32 14.76 14.76 0 00-1.11-5.6l33.6-33.6a5.59 5.59 0 002.74-4.09 5.63 5.63 0 00-1.56-4.68 5.63 5.63 0 00-4.65-1.63 5.62 5.62 0 00-4.14 2.67zm-37 49.79a3.39 3.39 0 01-3.56.5 3.41 3.41 0 01-2-3 3.34 3.34 0 011-2.4 3.35 3.35 0 012.41-1 3.58 3.58 0 012.41 1 3.37 3.37 0 011 2.4 3.34 3.34 0 01-1.23 2.19zM-388.28 133.94h-87a5.6 5.6 0 00-5.59 5.61 5.59 5.59 0 005.59 5.59h87a5.6 5.6 0 005.6-5.59 5.61 5.61 0 00-5.6-5.61zM-402.34 171.4h-44.8a5.6 5.6 0 00-5.6 5.6 5.6 5.6 0 005.6 5.6h44.8a5.6 5.6 0 005.6-5.6 5.6 5.6 0 00-5.6-5.6zM-416.34 208.47h-16.8a5.61 5.61 0 00-5.6 5.61 5.6 5.6 0 005.6 5.59h16.8a5.6 5.6 0 005.6-5.59 5.61 5.61 0 00-5.6-5.61z"
        transform="translate(480.9 -93)"
        stroke="#6E7699"
        fill="#6E7699"
      ></path>
    </svg>
  );
};

const User = (props) => {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M11.406 4.016a3.516 3.516 0 1 1-7.033 0 3.516 3.516 0 0 1 7.033 0Z"
        stroke="#6E7699"
      />
      <path d="M15.119 15.262a7.23 7.23 0 1 0-14.459 0" stroke="#6E7699" />
    </svg>
  );
};

const Arrow = (props) => {
  return (
    <svg
      width={9}
      height={6}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path d="m8.261 1.35-3.72 3.716L.821 1.35" stroke="#6E7699" />
    </svg>
  );
};

const EnglishFlag = () => {
  return (
    <svg width={20} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 .6h19.734v14.8H0V.6Z" fill="#41479B" />
      <path
        d="M19.734 13.473 12.437 8l7.297-5.473V.6h-2.57L9.868 6.073 2.57.6H0v1.927L7.298 8 0 13.473V15.4h2.57l7.297-5.473 7.298 5.473h2.57v-1.927Z"
        fill="#fff"
      />
      <path
        d="M0 .6v.77L8.84 8 0 14.63v.77h1.028l8.839-6.629 8.839 6.63h1.028v-.771L10.894 8l8.84-6.63V.6h-1.027l-8.84 6.63L1.027.6H0Z"
        fill="#DC251C"
      />
      <path
        d="M7.4 5.533V.6h4.934v4.933h7.4v4.934h-7.4V15.4H7.4v-4.933H0V5.533h7.4Z"
        fill="#fff"
      />
      <path
        d="M8.634 6.767V.6H11.1v6.167h8.634v2.466H11.1V15.4H8.634V9.233H0V6.767h8.634Z"
        fill="#DC251C"
      />
    </svg>
  );
};

const SwedishFlag = () => {
  return (
    <svg width={20} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="#3273D3" d="M19.734.6v14.8H0V.6z" />
      <path fill="#FFD018" d="M6.167.6h2.467v14.801H6.167z" />
      <path fill="#FFD018" d="M19.734 6.766v2.467H0V6.766z" />
    </svg>
  );
};

const Finished = (props) => {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={8} cy={8} r={8} fill="#65AD6E" />
      <path d="m5 8.391 1.272 1.613a1 1 0 0 0 1.57 0L11 6" stroke="#fff" />
    </svg>
  );
};

const OnGoing = (props) => {
  return (
    <svg
      width={18}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={9} cy={8.5} r={8.5} fill="#002C8B" />
      <path d="M12.188 8.5H5.811M9 5.313 12.188 8.5 9 11.688" stroke="#fff" />
    </svg>
  );
};

const NotOnGoing = (props) => {
  return (
    <svg
      width={6}
      height={7}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={3} cy={3.07} r={3} fill="#C0C6D9" />
    </svg>
  );
};

const MagnifyingGlass = (props) => (
  <svg
    width={15}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <path
      d="M11.24 11.119a5.857 5.857 0 1 1-8.283-8.283 5.857 5.857 0 0 1 8.282 8.283Zm0 0 2.76 2.76"
      stroke="#282B37"
    />
  </svg>
);

const CloseIcon = (props) => (
  <svg
    width={12}
    height={12}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 371.23 371.23"
    className={props.className}
    onClick={props.onClick}
  >
    <path d="M371.23 21.213 350.018 0 185.615 164.402 21.213 0 0 21.213l164.402 164.402L0 350.018l21.213 21.212 164.402-164.402L350.018 371.23l21.212-21.212-164.402-164.403z" />
  </svg>
);

const DownloadCloud = (props) => (
  <svg
    width={22}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <path
      d="M16.82 14.36c1.146 0 2.123-.404 2.93-1.212.833-.833 1.25-1.822 1.25-2.968 0-1.094-.378-2.032-1.133-2.813-.755-.807-1.666-1.25-2.734-1.328-.26-1.38-1.003-2.565-2.227-3.555C13.708 1.494 12.406 1 11 1a5.954 5.954 0 0 0-3.242.938 6.531 6.531 0 0 0-2.305 2.46c-1.224.13-2.279.69-3.164 1.68C1.43 7.042 1 8.135 1 9.36c0 1.38.482 2.566 1.445 3.555.99.964 2.175 1.445 3.555 1.445m4.998.835V7.68"
      stroke="#C0C6D9"
    />
    <path d="m14.044 11.859-3.043 3.043-3.044-3.043" stroke="#C0C6D9" />
  </svg>
);

const UploadCloud = (props) => (
  <svg
    width={16}
    height={11}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <path
      d="M12.074 9.883c.802 0 1.486-.282 2.051-.847.583-.584.875-1.276.875-2.078 0-.766-.264-1.422-.793-1.97-.529-.564-1.167-.874-1.914-.929-.182-.966-.702-1.795-1.559-2.488C9.896.878 8.984.53 8 .53c-.82 0-1.577.22-2.27.657A4.572 4.572 0 0 0 4.117 2.91c-.857.09-1.595.483-2.215 1.175C1.301 4.761 1 5.526 1 6.383c0 .966.337 1.796 1.012 2.489A3.441 3.441 0 0 0 4.5 9.883m3.502-4.675v5.26"
      stroke="#002C8B"
    />
    <path d="M5.87 7.543 8 5.413l2.13 2.13" stroke="#002C8B" />
  </svg>
);

const Document = (props) => (
  <svg
    width={15}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <path d="M1 17h13V6.5L8.5 1H1v16Z" stroke="#C0C6D9" />
    <path d="M8.5 1v5.5H14" stroke="#C0C6D9" />
  </svg>
);

const SmallArrow = (props) => (
  <svg
    width={20}
    height={9}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <path
      stroke={props.color || "#fff"}
      d="M0 5.347h19m-3.076-3.266 3.253 3.253-3.253 3.253"
    />
  </svg>
);

export {
  Clock,
  User,
  Arrow,
  EnglishFlag,
  SwedishFlag,
  OnGoing,
  Finished,
  NotOnGoing,
  MagnifyingGlass,
  CloseIcon,
  DownloadCloud,
  UploadCloud,
  Document,
  Spinner,
  SmallArrow,
  TimeFlag
};
