import { useTranslation } from "react-i18next";
import { UserDetails } from "../../components";

import Typography from "@mui/material/Typography";

const Account = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h1" color="primary.main">
        {t("my-account")}
      </Typography>
      <UserDetails />
    </>
  );
};

export default Account;
