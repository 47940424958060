import StatusBar from "../StatusBar/StatusBar";

import { Link } from "react-router-dom";
import styles from "./ProjectCard.module.scss";
import { cipDateFormat } from "../../utils";
import { useTranslation } from "react-i18next";

const ProjectCard = (props) => {
  const { t } = useTranslation();

  const {
    list,
    title,
    id,
    ongoingOrderType,
    endDate,
    startDate,
    newDate,
    link,
    showStatusBar = true,
    status,
    sort
  } = props;

  return (
    <Link to={link} className={styles.projectCard}>
      <div className={styles.content}>
        <div className={styles.left}>
          <h3>{title}</h3>
          <p>MTAB ID {id}</p>
        </div>
        {/* Only display if order is actually ongoing */}
        <ul className={styles.right}>
          <li className={list === "Ongoing" ? null : `${styles.hide}`}>
            <h4>{t("ongoingOrder")}</h4>
            <p>{ongoingOrderType}</p>
          </li>
          {sort === "asc" ? (
            <li className={endDate === "" ? `${styles.hide}` : null}>
              <h4>{t("projectEnd")}</h4>
              <p
                className={
                  (status.text === "Delayed" && styles.delayClass) ||
                  (status.text === "Changed" && styles.changedClass) ||
                  ""
                }
              >
                {endDate !== "" ? cipDateFormat(endDate) : ""}
              </p>
              {status.text === "Delayed" && newDate === "" ? null : (
                <p>{newDate}</p>
              )}
            </li>
          ) : (
            ""
          )}
          {sort === "desc" ? (
            <li className={startDate === "" ? `${styles.hide}` : null}>
              <h4>{t("projectStart")}</h4>
              <p
                className={
                  (status.text === "Delayed" && styles.delayClass) ||
                  (status.text === "Changed" && styles.changedClass) ||
                  ""
                }
              >
                {startDate !== "" ? cipDateFormat(startDate) : ""}
              </p>
              {status.text === "Delayed" && newDate === "" ? null : (
                <p>{newDate}</p>
              )}
            </li>
          ) : (
            ""
          )}
          <li>
            <StatusBar showStatus={showStatusBar} statusText={status.text} />
          </li>
        </ul>
      </div>
      <div className={styles.arrow}></div>
    </Link>
  );
};

export default ProjectCard;
