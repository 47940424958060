import { getDateWithAddedTime } from "utils";
import isValidDate from "./isValidDate";

const formatTime = (time) => {
  if (!time) {
    return "Time not found";
  }

  const formattedTime = time?.length > 5 ? time?.substring(0, 5) : time;

  return formattedTime;
};

const getOrderDates = (order) => {
  const {
    PlanedDateFrom,
    DeliveryDateTo,
    PlanedDateTo,
    PlanedTimeStart,
    PlanedTimeEnd,
    DeliveryTimeStart,
    DeliveryTimeEnd
  } = order?.OrderStatus?.[0] || {};

  // let startDate = PlanedDateFrom;
  // let endDate = DeliveryDateTo;

  let startDate = getDateWithAddedTime(PlanedDateFrom, PlanedTimeStart);
  let endDate = getDateWithAddedTime(DeliveryDateTo, DeliveryTimeEnd);

  if (isValidDate(endDate) === undefined) {
    endDate = "";
  }

  if (!isValidDate(endDate)) {
    endDate = getDateWithAddedTime(PlanedDateTo, PlanedTimeEnd);
  }

  if (!isValidDate(endDate)) {
    endDate = "";
  }

  const pickupTime =
    formatTime(PlanedTimeStart) + " - " + formatTime(PlanedTimeEnd);

  const deliveryTime =
    formatTime(DeliveryTimeStart) + " - " + formatTime(DeliveryTimeEnd);

  return {
    startDate: isValidDate(startDate) ? startDate : null,
    endDate,
    pickupTime: pickupTime || "All day",
    deliveryTime: deliveryTime || "All day"
  };
};

export default getOrderDates;
