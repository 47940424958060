import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useApi } from "../../context/context";
import { getContactPicture } from "../../utils";
import ContactCard from "../ContactCard/ContactCard";

import styles from "./Footer.module.scss";

const Footer = ({ isUserVerified }) => {
  const { companySalesPerson } = useApi();
  const [imgSrc, setImgSrc] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (companySalesPerson !== undefined) {
      (async function () {
        setImgSrc(await getContactPicture(companySalesPerson.Fullname));
      })();
    }
  }, [companySalesPerson]);

  return (
    <footer>
      <div className={styles.top}>
        {isUserVerified && <h2>{t("myMtabContact")}</h2>}
        {isUserVerified && companySalesPerson !== undefined ? (
          <ContactCard contactPerson={companySalesPerson} pictureSrc={imgSrc} />
        ) : null}
      </div>
      <div className={styles.bottom}>
        <a href="https://mtab.eu/" target="_blank" rel="noreferrer">
          MTAB.EU
        </a>
        <p>© MTAB</p>
      </div>
    </footer>
  );
};

export default Footer;
