import { Layout, ScrollHandler } from "../components";
import AppRoutes from "../AppRoutes";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import styles from "./App.scss";

const theme = createTheme({
  palette: {
    primary: {
      main: `${styles.primaryColor}`
    },
    secondary: {
      main: `${styles.secondaryColor}`
    },
    tertiary: {
      main: `${styles.tertiaryColor}`
    }
  },
  typography: {
    htmlFontSize: +styles.bodyTextSize.replace("px", ""), // should be number
    fontFamily: `${styles.mainFont}`,
    h1: {
      fontWeight: 400,
      fontSize: `${styles.headingSize}`,
      lineHeight: "140%",
      marginBottom: "1rem"
    },
    h2: {
      fontSize: `${styles.headingMdSize}`,
      lineHeight: "110%"
    },
    button: {
      fontSize: `${styles.bodyTextNormalSize}`,
      fontWeight: 400
    }
  }
});

// here app catches the suspense from page in case translations are not yet loaded
export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <ScrollHandler />
      <Layout>
        <AppRoutes />
      </Layout>
    </ThemeProvider>
  );
}

export { theme };
