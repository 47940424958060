import { useEffect, useState } from "react";

import styles from "./ContactCard.module.scss";
import avatar from "../../assets/images/avatar.png";

const ContactCard = ({ contactPerson, pictureSrc }) => {
  const { Fullname = "", Mobile = "", Email = "" } = contactPerson;
  const [number, setNumber] = useState(0);

  useEffect(() => {
    if (Mobile) {
      let noSpaces = Mobile?.replace(/\s/g, "");
      let formattedNr = noSpaces
        .match(/.{1,3}/g)
        .toString()
        .replace(/,/g, " ");

      setNumber(formattedNr);
    }
  }, [Mobile, contactPerson]);

  return (
    <div className={styles.contact}>
      <div>
        <p>{Fullname ? Fullname : null}</p>
        {Mobile ? <a href={`tel:${Mobile}`}>{number}</a> : null}
        {Email ? <a href={`mailto:${Email}`}>{Email}</a> : null}
      </div>
      <img
        src={pictureSrc !== "" ? pictureSrc : avatar}
        alt={
          pictureSrc !== ""
            ? `Portrait picture of ${Fullname}`
            : "Anonymous profile picture"
        }
      />
    </div>
  );
};

export default ContactCard;
