const getProjectStatus = (project) => {
  const { StatusNo = 0 } = project.ProjectStatus[0];

  switch (StatusNo) {
    case 10:
      return { text: "" };
    case 20:
      return { text: "On time" };
    case 21:
      return { text: "On time" };
    case 21:
      return { text: "On time" };
    case 30:
      return { text: "Completed" };
    case 31:
      return { text: "Completed" };
    case 32:
      return { text: "Invoiced" };
    default:
      return { text: "" };
  }
};

export default getProjectStatus;
