import { useTranslation } from "react-i18next";
import { useApi } from "../../context/context";
import { TableList } from "../../components";
import { cipDateFormat } from "../../utils";
import { DownloadCloud, Document } from "../../assets/icons";
import Typography from "@mui/material/Typography";
import { fileDownload } from "../../api/file";

const Invoices = () => {
  const { invoices } = useApi();
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h1" color="primary.main">
        {t("invoices")}
      </Typography>
      <TableList
        data={invoices}
        canUpload={false}
        columns={[
          {
            name: "InvoiceNumber",
            title: "#",
            modifier: (v) => (
              <>
                <span className="td-icon">
                  <Document />
                </span>{" "}
                {v}
              </>
            )
          },
          {
            name: "InvoiceDate",
            title: "Date",
            modifier: cipDateFormat
          },
          {
            name: "Description",
            title: "Description",
            modifier: (v) => <strong>{v}</strong>
          },
          {
            name: "ProjectIDs",
            title: "Project ID"
          },
          {
            name: "SumExVAT",
            title: "Amount (ex Vat)",
            modifier: (v) => v + " SEK"
          },
          {
            name: "InvoiceNumber",
            title: "",
            modifier: (v) => (
              <a
                target="_blank"
                download
                href={fileDownload(
                  "Invoices/invoice-" + v + ".pdf",
                  "external",
                  "invoice-" + v + ".pdf"
                )}
                style={{ paddingRight: "10px" }}
                rel="noreferrer"
              >
                <DownloadCloud />
              </a>
            )
          }
        ]}
      />
    </>
  );
};

export default Invoices;
