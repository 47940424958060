/**
 *
 * @param {*} order - order with certain ID
 * @returns array of tab titles (string), with filtered lists associated with tab title in a content array
 */

/**
## Transport / Spedition
PickupCountry
PickupCompany
PickupDepartment
PickupAdress
PickupZipCode
PickupCity
Pickup site info
PickupSiteInfoUnknown
PickupLoadingDock
PickupSteps
PickupNoOfSteps
PickupNoOfFloors
PickupElevator
PickupElevatorMaxWeight
PickupNarrowDoors
PickupCobbleStones
PickupNoSiteInfoRegistered
PickupNotesToSiteInfo
DeliveryCountry
DeliveryCompany
DeliveryDepartment
DeliveryAdress
DeliveryZipCode
DeliveryCity
Delivery Site info
DeliverySiteInfoUnknown
DeliveryLoadingDock
DeliverySteps
DeliveryNoOfSteps
DeliveryNoOfFloors
DeliveryElevator
DeliveryElevatorMaxWeight
DeliveryNarrowDoors
DeliveryCobbleStones
DeliveryNoSiteInfoRegistered
DeliveryNotesToSiteInfo
PackageCount
PackageWeight
PackageVolume
PackageFlakMeter
PackageDescription
PackageCustomerLabeling

## Terminal
Or21_Rubrik
Or56_Ext_Beskrivning
TerminalOrderLocation

## Packorder
Or21_Rubrik
Or56_Ext_Beskrivning
PackageCount
PackageWeight
PackageVolume
PackageFlakMeter
PackageDescription
PackageCustomerLabeling

## LagerOrder
Or21_Rubrik
Or56_Ext_Beskrivning
WarehouseWorkType
PackageCount
PackageWeight
PackageVolume
PackageFlakMeter
PackageDescription
PackageCustomerLabeling
WarehouseCity (edited) 
   */

import {
  getOrderType,
  getOrderDates,
  cipDateFormat,
  addMeasureUnit,
  formatPickupAddress,
  formatDeliveryAddress,
  minimizeTimeSpan
} from "./index";

export const tabifyOrder = (order) => {
  // Date & times
  const orderDates = getOrderDates(order);
  const startDate = orderDates.startDate;
  const endDate = orderDates.endDate;
  const pickupTime = orderDates.pickupTime;
  const deliveryTime = orderDates.deliveryTime;
  const FormatedStart = cipDateFormat(startDate, true);
  const FormatedEnd = cipDateFormat(endDate, true);

  const pickupAddress = {
    PickupAdress: order.PickupAdress,
    PickupZipCode: order.PickupZipCode,
    PickupCity: order.PickupCity,
    PickupCountry: order.PickupCountry
  };
  // console.log("order", order);
  const deliveryAddress = {
    DeliveryAdress: order.DeliveryAdress,
    DeliveryZipCode: order.DeliveryZipCode,
    DeliveryCity: order.DeliveryCity,
    DeliveryCountry: order.DeliveryCountry
  };

  const formattedDeliveryAddress = formatDeliveryAddress(deliveryAddress);
  const formattedPickupAddress = formatPickupAddress(pickupAddress);

  if (getOrderType(order) === "Transport") {
    return [
      {
        title: "Order details",
        content: [
          {
            title: "Summary",
            position: "left",
            list: {
              PickupCompany: order.PickupCompany,
              PickupAddress: formattedPickupAddress.Address,
              PickupTime:
                minimizeTimeSpan(pickupTime) + "<br />" + FormatedStart,
              DeliveryCompany: order.DeliveryCompany,
              DeliveryAddress: formattedDeliveryAddress.Address,
              DeliveryTime:
                minimizeTimeSpan(deliveryTime) + "<br />" + FormatedEnd
            }
          },
          {
            title: "Pickup Site Info",
            position: "right",
            list: {
              PickupDepartment: order.PickupDepartment,
              PickupElevator: order.PickupElevator,
              PickupElevatorMaxWeight: order.PickupElevatorMaxWeight,
              PickupLoadingDock: order.PickupLoadingDock,
              PickupNarrowDoors: order.PickupNarrowDoors,
              PickupNoOfFloors: order.PickupNoOfFloors,
              PickupNoOfSteps: order.PickupNoOfSteps,
              // PickupNoSiteInfoRegistered: order.PickupNoSiteInfoRegistered,
              PickupNotesToSiteInfo: order.PickupNotesToSiteInfo,
              // PickupSiteInfoUnknown: order.PickupSiteInfoUnknown,
              PickupSteps: order.PickupSteps
            }
          },
          {
            title: "Delivery Site Info",
            position: "right",
            list: {
              DeliverySteps: order.DeliverySteps,
              DeliveryCobbleStones: order.DeliveryCobbleStones,
              DeliveryDepartment: order.DeliveryDepartment,
              DeliveryElevator: order.DeliveryElevator,
              DeliveryElevatorMaxWeight: order.DeliveryElevatorMaxWeight,
              DeliveryLoadingDock: order.DeliveryElevatorMaxWeight,
              DeliveryNarrowDoors: order.DeliveryNarrowDoors,
              DeliveryNoOfFloors: order.DeliveryNoOfFloors,
              DeliveryNoOfSteps: order.DeliveryNoOfSteps,
              DeliveryNoSiteInfoRegistered: order.DeliveryNoSiteInfoRegistered,
              DeliveryNotesToSiteInfo: order.DeliveryNotesToSiteInfo
              // DeliverySiteInfoUnknown: order.DeliverySiteInfoUnknown
            }
          },
          {
            title: "Package info",
            position: "right",
            list: {
              PackageCount: order.PackageCount,
              PackageCustomerLabeling: order.PackageCustomerLabeling,
              PackageDescription: order.PackageDescription,
              PackageFlakMeter: addMeasureUnit(
                "PackageFlakMeter",
                order.PackageFlakMeter
              ),
              PackageVolume: addMeasureUnit(
                "PackageVolume",
                order.PackageVolume
              ),
              PackageVolumeWeight: addMeasureUnit(
                "PackageVolumeWeight",
                order.PackageVolumeWeight
              ),
              PackageWeight: addMeasureUnit(
                "PackageWeight",
                order.PackageWeight
              ),
              PackingOrderMarking: order.PackingOrderMarking
            }
          }
        ]
      },
      {
        title: "Order events"
      },
      {
        title: "Documents",
        content: []
      }
    ];
  }

  if (getOrderType(order) === "Lagerorder") {
    return [
      {
        title: "Order details",
        content: [
          {
            title: "Summary",
            position: "left",
            list: {
              Date:
                cipDateFormat(order.TerminalOrderDate) ||
                minimizeTimeSpan(pickupTime) + "<br />" + FormatedStart,
              WarehouseCity: order.WarehouseCity,
              Description: order.InvoiceText,
              WarehouseWorkType: order.WarehouseWorkType
            }
          },
          {
            title: "Package info",
            position: "right",
            list: {
              PackageCount: order.PackageCount,
              PackageCustomerLabeling: order.PackageCustomerLabeling,
              PackageDescription: order.PackageDescription,
              PackageFlakMeter: addMeasureUnit(
                "PackageFlakMeter",
                order.PackageFlakMeter
              ),
              PackageVolume: addMeasureUnit(
                "PackageVolume",
                order.PackageVolume
              ),
              PackageVolumeWeight: addMeasureUnit(
                "PackageVolumeWeight",
                order.PackageVolumeWeight
              ),
              PackageWeight: addMeasureUnit(
                "PackageWeight",
                order.PackageWeight
              ),
              PackingOrderBoxType: order.PackingOrderBoxType,
              PackingOrderMarking: order.PackingOrderMarking
            }
          }
        ]
      },
      {
        title: "Order events"
      },
      {
        title: "Documents",
        content: []
      }
    ];
  }
  if (getOrderType(order) === "Terminal") {
    return [
      {
        title: "Order details",
        content: [
          {
            title: "Summary",
            position: "left",
            list: {
              Date:
                cipDateFormat(order.TerminalOrderDate) ||
                minimizeTimeSpan(deliveryTime) + "<br />" + FormatedStart,
              Description: order.InvoiceText,
              WarehouseCity: order.WarehouseCity,
              TerminalLocation: order.TerminalOrderLocation
            }
          }
        ]
      },
      {
        title: "Order events"
      },
      {
        title: "Documents",
        content: []
      }
    ];
  }
  if (getOrderType(order) === "Packorder") {
    return [
      {
        title: "Order details",
        content: [
          {
            title: "Summary",
            position: "left",
            list: {
              Date:
                cipDateFormat(order.TerminalOrderDate) ||
                minimizeTimeSpan(pickupTime) + "<br />" + FormatedStart,
              Description: order.InvoiceText,
              WarehouseCity: order.WarehouseCity,
              TerminalLocation: order.TerminalOrderLocation
            }
          },
          {
            title: "Package info",
            position: "right",
            list: {
              PackageCount: order.PackageCount,
              PackageCustomerLabeling: order.PackageCustomerLabeling,
              PackageDescription: order.PackageDescription,
              PackageFlakMeter: addMeasureUnit(
                "PackageFlakMeter",
                order.PackageFlakMeter
              ),
              PackageVolume: addMeasureUnit(
                "PackageVolume",
                order.PackageVolume
              ),
              PackageVolumeWeight: addMeasureUnit(
                "PackageVolumeWeight",
                order.PackageVolumeWeight
              ),
              PackageWeight: addMeasureUnit(
                "PackageWeight",
                order.PackageWeight
              ),
              PackingOrderBoxType: order.PackingOrderBoxType,
              PackingOrderMarking: order.PackingOrderMarking
            }
          }
        ]
      },
      {
        title: "Order events"
      },
      {
        title: "Documents",
        content: []
      }
    ];
  }
  if (getOrderType(order) === "TransportOLD") {
    return [
      {
        title: "Order events"
      },
      {
        title: "Order details",
        content: [
          {
            title: "Summary",
            list: {
              CompanyCustomerID: order.CompanyCustomerID,
              CompanyPayerID: order.CompanyPayerID,
              Created: cipDateFormat(order.Created),
              CreatedBy: order.CreatedBy,
              DeliveryCobbleStones: order.DeliveryCobbleStones,
              DeliveryCompany: order.DeliveryCompany,
              DeliveryAddress: formattedDeliveryAddress.Address,
              DeliveryDateFinal: cipDateFormat(order.DeliveryDateFinal),
              DeliveryDateFrom: cipDateFormat(order.DeliveryDateFrom),
              DeliveryDateTo: cipDateFormat(order.DeliveryDateTo),
              DeliveryDepartment: order.DeliveryDepartment,
              DeliveryElevator: order.DeliveryElevator,
              DeliveryElevatorMaxWeight: order.DeliveryElevatorMaxWeight,
              DeliveryLoadingDock: order.DeliveryElevatorMaxWeight,
              DeliveryNarrowDoors: order.DeliveryNarrowDoors,
              DeliveryNoOfFloors: order.DeliveryNoOfFloors,
              DeliveryNoOfSteps: order.DeliveryNoOfSteps,
              DeliveryNoSiteInfoRegistered: order.DeliveryNoSiteInfoRegistered,
              DeliveryNotesToSiteInfo: order.DeliveryNotesToSiteInfo,
              // DeliverySiteInfoUnknown: order.DeliverySiteInfoUnknown,
              DeliverySteps:
                "Toshiba TEC Nordic  - Framplock av: 1st E-STUDIO2000AC\r\rMcnr:CFIF23715      \rFr. löpnr: 107203",
              DeliveryTime: cipDateFormat(
                order.DeliveryDateTo,
                order.DeliveryDateFinal,
                false,
                true
              ),
              Header: order.Header,
              ID: order.ID,
              InvoiceText: order.InvoiceText,
              Modified: cipDateFormat(order.Modified),
              ModifiedBy: order.ModifiedBy,
              OrderID: order.OrderID,
              OrderStatus: order.OrderStatus,
              PackageCount: order.PackageCount,
              PackageCustomerLabeling: order.PackageCustomerLabeling,
              PackageDescription: order.PackageDescription,
              PackageFlakMeter: addMeasureUnit(
                "PackageFlakMeter",
                order.PackageFlakMeter
              ),
              PackageVolume: addMeasureUnit(
                "PackageVolume",
                order.PackageVolume
              ),
              PackageVolumeWeight: addMeasureUnit(
                "PackageVolumeWeight",
                order.PackageVolumeWeight
              ),
              PackageWeight: addMeasureUnit(
                "PackageWeight",
                order.PackageWeight
              ),
              PackingOrderBoxType: order.PackingOrderBoxType,
              PackingOrderDate: cipDateFormat(order.PackingOrderDate),
              PackingOrderDeliverDay: order.PackingOrderDeliverDay,
              PackingOrderDeliverTo: order.PackingOrderDeliverTo,
              PackingOrderDestination: order.PackingOrderDestination,
              PackingOrderMarking: order.PackingOrderMarking,
              OrderType: order.OrderType,
              PickupCobbleStones: order.PickupCobbleStones,
              PickupCompany: order.PickupCompany,
              PickupAddress: formattedPickupAddress.Address,
              PickupDateFinal: cipDateFormat(order.PickupDateFinal, true),
              PickupDateFrom: cipDateFormat(order.PickupDateFrom, true),
              PickupDateTo: cipDateFormat(order.PickupDateTo, true),
              PickupDepartment: order.PickupDepartment,
              PickupElevator: order.PickupElevator,
              PickupElevatorMaxWeight: order.PickupElevatorMaxWeight,
              PickupLoadingDock: order.PickupLoadingDock,
              PickupNarrowDoors: order.PickupNarrowDoors,
              PickupNoOfFloors: order.PickupNoOfFloors,
              PickupNoOfSteps: order.PickupNoOfSteps,
              PickupNoSiteInfoRegistered: order.PickupNoSiteInfoRegistered,
              PickupNotesToSiteInfo: order.PickupNotesToSiteInfo,
              // PickupSiteInfoUnknown: order.PickupSiteInfoUnknown,
              PickupSteps: order.PickupSteps,
              PickupTime: order.PickupTime,
              ProjectID: order.ProjectID,
              SourceCreated: cipDateFormat(order.SourceCreated),
              SourceLastChanged: cipDateFormat(order.SourceLastChanged),
              StatusPlanned: cipDateFormat(order.StatusPlanned),
              TerminalOrderDate: cipDateFormat(order.TerminalOrderDate),
              TerminalOrderLocation: order.TerminalOrderLocation,
              WarehouseArrivingDate: cipDateFormat(order.WarehouseArrivingDate),
              WarehouseCity: order.WarehouseCity,
              WarehouseFinnishDate: cipDateFormat(order.WarehouseFinnishDate),
              WarehouseFinnishTime: cipDateFormat(order.WarehouseFinnishTime)
            } //DetailList recieves object
          },
          {
            title: "Package",
            list: {
              PackageCount: order.PackageCount,
              PackageCustomerLabeling: order.PackageCustomerLabeling,
              PackageDescription: order.PackageDescription,
              PackageFlakMeter: addMeasureUnit(
                "PackageFlakMeter",
                order.PackageFlakMeter
              ),
              PackageVolume: addMeasureUnit(
                "PackageVolume",
                order.PackageVolume
              ),
              PackageVolumeWeight: addMeasureUnit(
                "PackageVolumeWeight",
                order.PackageVolumeWeight
              ),
              PackageWeight: addMeasureUnit(
                "PackageWeight",
                order.PackageWeight
              )
            }
          }
        ]
      },
      {
        title: "Site info",
        content: [
          {
            title: "Pickup",
            list: {
              PickupCobbleStones: order.PickupCobbleStones,
              PickupCompany: order.PickupCompany,
              PickupAddress: formattedPickupAddress.Address,
              PickupDateFinal: cipDateFormat(order.PickupDateFinal, true),
              PickupDateFrom: cipDateFormat(order.PickupDateFrom, true),
              PickupDateTo: cipDateFormat(order.PickupDateTo, true),
              PickupDepartment: order.PickupDepartment,
              PickupElevator: order.PickupElevator,
              PickupElevatorMaxWeight: order.PickupElevatorMaxWeight,
              PickupLoadingDock: order.PickupLoadingDock,
              PickupNarrowDoors: order.PickupNarrowDoors,
              PickupNoOfFloors: order.PickupNoOfFloors,
              PickupNoOfSteps: null,
              PickupNoSiteInfoRegistered: "1",
              PickupNotesToSiteInfo: null,
              // PickupSiteInfoUnknown: null,
              PickupSteps: null,
              PickupTime: order.PickupTime
            }
          },
          {
            title: "Delivery",
            list: {
              DeliveryCobbleStones: order.DeliveryCobbleStones,
              DeliveryCompany: order.DeliveryCompany,
              DeliveryAddress: formattedDeliveryAddress.Address,
              DeliveryDateFinal: cipDateFormat(order.DeliveryDateFinal),
              DeliveryDateFrom: cipDateFormat(order.DeliveryDateFrom),
              DeliveryDateTo: cipDateFormat(order.DeliveryDateTo),
              DeliveryDepartment: order.DeliveryDepartment,
              DeliveryElevator: order.DeliveryElevator,
              DeliveryElevatorMaxWeight: order.DeliveryElevatorMaxWeight,
              DeliveryLoadingDock: order.DeliveryElevatorMaxWeight,
              DeliveryNarrowDoors: order.DeliveryNarrowDoors,
              DeliveryNoOfFloors: order.DeliveryNoOfFloors,
              DeliveryNoOfSteps: order.DeliveryNoOfSteps,
              DeliveryNoSiteInfoRegistered: order.DeliveryNoSiteInfoRegistered,
              DeliveryNotesToSiteInfo: order.DeliveryNotesToSiteInfo,
              // DeliverySiteInfoUnknown: order.DeliverySiteInfoUnknown,
              DeliverySteps:
                "Toshiba TEC Nordic  - Framplock av: 1st E-STUDIO2000AC\r\rMcnr:CFIF23715      \rFr. löpnr: 107203",
              DeliveryTime: cipDateFormat(
                order.DeliveryDateTo,
                order.DeliveryDateFinal,
                false,
                true
              )
            }
          }
        ]
      },
      {
        title: "Documents",
        content: []
      }
    ];
  }
  // default
  return [
    {
      title: "Order events"
    },
    {
      title: "Order details",
      content: [
        {
          title: "Summary",
          list: {
            CompanyCustomerID: order.CompanyCustomerID,
            CompanyPayerID: order.CompanyPayerID,
            Created: cipDateFormat(order.Created),
            CreatedBy: order.CreatedBy,
            DeliveryAdress: order.DeliveryAdress,
            DeliveryCity: order.DeliveryCity,
            DeliveryCobbleStones: order.DeliveryCobbleStones,
            DeliveryCompany: order.DeliveryCompany,
            DeliveryCountry: order.DeliveryCountry,
            DeliveryDateFinal: cipDateFormat(order.DeliveryDateFinal),
            DeliveryDateFrom: cipDateFormat(order.DeliveryDateFrom),
            DeliveryDateTo: cipDateFormat(order.DeliveryDateTo),
            DeliveryDepartment: order.DeliveryDepartment,
            DeliveryElevator: order.DeliveryElevator,
            DeliveryElevatorMaxWeight: order.DeliveryElevatorMaxWeight,
            DeliveryLoadingDock: order.DeliveryElevatorMaxWeight,
            DeliveryNarrowDoors: order.DeliveryNarrowDoors,
            DeliveryNoOfFloors: order.DeliveryNoOfFloors,
            DeliveryNoOfSteps: order.DeliveryNoOfSteps,
            DeliveryNoSiteInfoRegistered: order.DeliveryNoSiteInfoRegistered,
            DeliveryNotesToSiteInfo: order.DeliveryNotesToSiteInfo,
            // DeliverySiteInfoUnknown: order.DeliverySiteInfoUnknown,
            DeliverySteps:
              "Toshiba TEC Nordic  - Framplock av: 1st E-STUDIO2000AC\r\rMcnr:CFIF23715      \rFr. löpnr: 107203",
            DeliveryTime: cipDateFormat(
              order.DeliveryDateTo,
              order.DeliveryDateFinal,
              false,
              true
            ),
            DeliveryZipCode: order.DeliveryZipCode,
            Header: order.Header,
            ID: order.ID,
            InvoiceText: order.InvoiceText,
            Modified: cipDateFormat(order.Modified),
            ModifiedBy: order.ModifiedBy,
            OrderID: order.OrderID,
            OrderStatus: order.OrderStatus,
            PackageCount: order.PackageCount,
            PackageCustomerLabeling: order.PackageCustomerLabeling,
            PackageDescription: order.PackageDescription,
            PackageFlakMeter: addMeasureUnit(
              "PackageFlakMeter",
              order.PackageFlakMeter
            ),
            PackageVolume: addMeasureUnit("PackageVolume", order.PackageVolume),
            PackageVolumeWeight: addMeasureUnit(
              "PackageVolumeWeight",
              order.PackageVolumeWeight
            ),
            PackageWeight: addMeasureUnit("PackageWeight", order.PackageWeight),
            PackingOrderBoxType: order.PackingOrderBoxType,
            PackingOrderDate: cipDateFormat(order.PackingOrderDate),
            PackingOrderDeliverDay: order.PackingOrderDeliverDay,
            PackingOrderDeliverTo: order.PackingOrderDeliverTo,
            PackingOrderDestination: order.PackingOrderDestination,
            PackingOrderMarking: order.PackingOrderMarking,
            OrderType: order.OrderType,
            PickupAdress: order.PickupAdress,
            PickupCity: order.PickupCity,
            PickupCobbleStones: order.PickupCobbleStones,
            PickupCompany: order.PickupCompany,
            PickupCountry: order.PickupCountry,
            PickupDateFinal: cipDateFormat(order.PickupDateFinal, true),
            PickupDateFrom: cipDateFormat(order.PickupDateFrom, true),
            PickupDateTo: cipDateFormat(order.PickupDateTo, true),
            PickupDepartment: order.PickupDepartment,
            PickupElevator: order.PickupElevator,
            PickupElevatorMaxWeight: order.PickupElevatorMaxWeight,
            PickupLoadingDock: order.PickupLoadingDock,
            PickupNarrowDoors: order.PickupNarrowDoors,
            PickupNoOfFloors: order.PickupNoOfFloors,
            PickupNoOfSteps: order.PickupNoOfSteps,
            PickupNoSiteInfoRegistered: order.PickupNoSiteInfoRegistered,
            PickupNotesToSiteInfo: order.PickupNotesToSiteInfo,
            // PickupSiteInfoUnknown: order.PickupSiteInfoUnknown,
            PickupSteps: order.PickupSteps,
            PickupTime: order.PickupTime,
            PickupZipCode: order.PickupZipCode,
            ProjectID: order.ProjectID,
            SourceCreated: cipDateFormat(order.SourceCreated),
            SourceLastChanged: cipDateFormat(order.SourceLastChanged),
            StatusPlanned: cipDateFormat(order.StatusPlanned),
            TerminalOrderDate: cipDateFormat(order.TerminalOrderDate),
            TerminalOrderLocation: order.TerminalOrderLocation,
            WarehouseArrivingDate: cipDateFormat(order.WarehouseArrivingDate),
            WarehouseCity: order.WarehouseCity,
            WarehouseFinnishDate: cipDateFormat(order.WarehouseFinnishDate),
            WarehouseFinnishTime: cipDateFormat(order.WarehouseFinnishTime)
          } //DetailList recieves object
        }
      ]
    },
    {
      title: "Documents",
      content: []
    },
    {
      title: "Invoices"
    }
  ];
};
