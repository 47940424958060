const withinHours = (date, hours = 24) => {
  const now = new Date();
  const end = new Date(date);
  const diff = end.getTime() - now.getTime();
  return diff < hours * 60 * 60 * 1000 && diff > 0;
};

const minimizeTimeSpan = (span) => {
  const split = span
    .replace(" ", "")
    .split("-")
    .map((a) => {
      const [H, M] = a.split(":");
      return +H + ":" + M;
    });
  if (split[0] === split[1]) {
    return split[0];
  }
  return split.join(" - ");
};

export { withinHours, minimizeTimeSpan };
