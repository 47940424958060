// https://react-google-maps-api-docs.netlify.app
import { GoogleMap, Marker } from "@react-google-maps/api";

import { useState, useEffect, memo } from "react";
import Geocoder from "nominatim-geocoder";

import styles from "./ThumbMap.module.scss";
import thumbMapStyle from "./ThumbMapStyle";
import whiteMarker from "../../assets/images/whitemarker.svg";

const geocoder = new Geocoder({
  secure: true
});

const defaultMapOptions = {
  disableDefaultUI: true,
  styles: thumbMapStyle
};

const ThumbMap = (props) => {
  const { zoom = 7, disable = true, to = {} } = props;

  const [latLng, setLatLng] = useState(undefined);

  useEffect(() => {
    let isCancelled = false;
    if (to) {
      const getCoordinates = async () => {
        const searchParams = {
          q: `${to.address}, ${to.city}`,
          countrycodes: "se"
        };

        const [firstResult] = await geocoder.search(searchParams);

        if (!isCancelled && firstResult) {
          setLatLng({
            lat: +firstResult.lat,
            lng: +firstResult.lon
          });
        }
      };

      getCoordinates();
    }

    return () => {
      isCancelled = true;
    };
  }, [to]);

  // console.log("location", to);
  // console.log("location", latLng);

  if (latLng) {
    return (
      <div className={`${styles.mapWrapper} ${disable && styles.disable}`}>
        <GoogleMap
          options={defaultMapOptions}
          zoom={zoom}
          center={latLng}
          mapContainerStyle={{
            height: "118%",
            width: "100%"
          }}
        >
          <Marker
            icon={{
              url: whiteMarker
            }}
            position={latLng}
            animation="DROP"
          />
        </GoogleMap>
      </div>
    );
  } else {
    return (
      <div className={`${styles.mapWrapper} ${disable && styles.disable}`}>
        <GoogleMap
          options={defaultMapOptions}
          zoom={4}
          center={{
            lat: 60.1282,
            lng: 18.6435
          }}
          mapContainerStyle={{
            height: "118%",
            width: "100%"
          }}
        ></GoogleMap>
      </div>
    );
  }
};

export default memo(ThumbMap);
