import { useEffect, useState } from "react";

import styles from "./StatusBar.module.scss";

const StatusBar = ({
  showStatus = true,
  statusText = "",
  notificationDropdown = false,
  hasOderEvents = false
}) => {
  const [btnClass, setBtnClass] = useState("green");
  const [notifyStatusClass, setNotifyStatusClass] = useState("otherStatusBar");

  useEffect(() => {
    if (notificationDropdown) setNotifyStatusClass("notifyStatusBar");
  }, [notificationDropdown]);

  useEffect(() => {
    if (
      statusText === "On time" ||
      statusText === "On Time" ||
      statusText === "At client"
    ) {
      setBtnClass("onTime");
    } else if (statusText === "Changed") {
      setBtnClass("changed");
    } else if (statusText === "Delayed") {
      setBtnClass("delay");
    } else if (statusText === "Completed") {
      setBtnClass("completed");
    } else if (statusText === "Invoiced") {
      setBtnClass("invoiced");
    } else if (showStatus === false) {
      setBtnClass("hide");
    } else {
      setBtnClass("hide");
    }
  }, [statusText, showStatus]);

  return (
    <div
      className={`${styles.orderStatus} ${styles[btnClass]} ${
        styles[notifyStatusClass]
      }
        ${hasOderEvents ? styles.hasOderEvents : ""}`}
    >
      <p>{statusText}</p>
    </div>
  );
};

export default StatusBar;
