import { useEffect, useState } from "react";
import styles from "./Truck.module.scss";

const Truck = (props) => {
  const { isDelayed, startTime, endTime } = props;
  const [line, setLine] = useState(0);

  useEffect(() => {
    const currentTime = new Date().getTime();

    const orderStartUnixTime = new Date(startTime).getTime();
    const orderEndUnixTime = new Date(endTime).getTime();

    const fullWidth = orderEndUnixTime - orderStartUnixTime; // 100%

    const position = (currentTime - orderStartUnixTime) / fullWidth;
    const lineCalc = position * 100;

    //max allowed should be 100%

    if (isDelayed) {
      setLine(50);
    } else {
      if (lineCalc >= 100) {
        setLine(100);
      } else {
        setLine(lineCalc);
      }
    }
  }, [startTime, endTime, isDelayed]);

  return (
    <>
      <svg
        className={styles.truck}
        fill="none"
        height="38"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 63 38"
        style={{
          left: `calc(${line > 12 ? line : 0}% - ${line > 12 ? 53 : 0}px)`
        }}
      >
        <path
          d="M39.332 1H5.849a4 4 0 0 0-4 4v26.833h41.483V5a4 4 0 0 0-4-4Z"
          fill="#002C8B"
          stroke="#fff"
          strokeWidth={2}
        />
        <path
          d="M43.331 7.719h8.67c5.522 0 10 4.477 10 10v14.114H43.33V7.72Z"
          fill="#002C8B"
          stroke="#fff"
          strokeWidth={2}
        />
        <path
          d="M15.16 32.29a3.854 3.854 0 0 1-3.85 3.857 3.854 3.854 0 0 1-3.85-3.858M23.81 32.29a3.854 3.854 0 0 1-3.85 3.857 3.854 3.854 0 0 1-3.85-3.858M53.031 32.29a3.854 3.854 0 0 1-3.85 3.857 3.854 3.854 0 0 1-3.85-3.858"
          stroke="#002C8B"
          strokeWidth={2}
        />
        <path
          d="M15.68 20.743c-6.56.01-8.613-1.14-8.975-1.379A9.25 9.25 0 0 0 9.1 23.475a9.24 9.24 0 0 0 6.575 2.723 9.247 9.247 0 0 0 6.584-2.728 9.262 9.262 0 0 0 2.415-4.174c-.24.176-2.19 1.437-8.994 1.447ZM15.675 11.862c6.804-.03 8.64 1.062 8.879 1.229a9.262 9.262 0 0 0-2.286-3.725 9.26 9.26 0 0 0-6.593-2.731c-2.486 0-4.826.969-6.583 2.726a9.287 9.287 0 0 0-2.369 4.004c.304-.235 2.271-1.473 8.952-1.503Z"
          fill="#fff"
        />
        <path
          d="M16.93 14.43c-.688.01-1.345-.016-2.002.015 0 0-.026 1.643-.01 2.686.02 1.042.02 1.626.031 2.304-.584.01-1.126 0-1.695-.031l-.031-1.7-.031-3.207c-.61-.026-1.862.063-1.862.063-.015-.365.047-.74.063-1.1 0 0 .51-.094 2.758-.136 2.086-.042 2.378-.047 2.753-.021l.026 1.126ZM10.98 13.492s-.173 3.113.051 5.803c0 0-.766-.015-1.256-.11 0 0-.292-2.393-.12-4.588 0 0-.84 2.597-1.07 4.401l-.73-.099c-.453-1.283-.672-2.66-.886-4.046 0 0-.234 1.804.037 3.88l-.454-.116c-.208-.886-.162-1.402-.177-2.424-.016-1.022.214-1.982.214-1.982.318-.11.646-.156.975-.24.062 1.46.5 3.62.5 3.62.026-.355.569-3.025.934-3.86a32.584 32.584 0 0 1 1.981-.24ZM20.251 13.445c.803 1.825 1.544 3.67 1.971 5.642-.427.052-.86.125-1.288.13l-.344-1.11c-.803.005-1.622.046-2.425.078-.198.406-.354.824-.547 1.23-.5.052-1.57.047-1.57.047.657-1.486 2.446-6.095 2.446-6.095.74-.01 1.178.026 1.757.078Zm-.865 1.194c-.303.803-.522 1.632-.845 2.425.558-.006 1.116-.021 1.668-.032-.24-.813-.547-1.6-.823-2.393ZM24.163 13.935c.474.146.756.646.803 1.09.093.907-.329 1.173-.329 1.173s.495.177.443 1.11c-.026.444-.15.928-.625 1.283-.225.167-.647.24-1.022.303-.423.067-.762.12-.856.12.172-1.747.125-3.645-.036-5.371l-.005-.032c.542.068 1.105.162 1.627.324Zm-.637.641.052 1.215c.496.042.976.125.887-.558-.089-.63-.6-.636-.939-.657Zm.063 2.174-.02 1.273c.338-.032.901-.01 1-.725.105-.777-.724-.579-.98-.548Z"
          fill="#fff"
        />
      </svg>
      <hr style={{ width: line + "%" }} />
    </>
  );
};

export default Truck;
