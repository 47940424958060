const { REACT_APP_BASE_URL, NODE_ENV } = process.env;
const LANGUAGE_LIST = [
  { id: 1, lang: "English", code: "Eng" },
  { id: 2, lang: "Svenska", code: "Sve" }
];
const GOOGLE_MAPS_API_KEY = "AIzaSyCrYRAN0cFnBUaStPiBoOeQeVVypYHEvWU";
const REACT_APP_PAGE_SIZE = 5;
const IS_DEBUG = NODE_ENV === "development";
const BASE_URL = REACT_APP_BASE_URL || "";

export {
  LANGUAGE_LIST,
  GOOGLE_MAPS_API_KEY,
  BASE_URL,
  REACT_APP_PAGE_SIZE,
  IS_DEBUG
};
