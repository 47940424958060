import dayjs from "dayjs";
import orderBy from "lodash/orderBy";

export { default as addMeasureUnit } from "./addMeasureUnit";
export { default as cipDateFormat } from "./cipDateFormat";
export { default as getContactPicture } from "./getContactPicture";
export { default as getFileInPath } from "./getFileInPath";
export { default as getOrderDates } from "./getOrderDates";
export { default as getOrderStatus } from "./getOrderStatus";
export { default as getOrderType } from "./getOrderType";
export { default as getProjectStatus } from "./getProjectStatus";
export { default as isValidDate } from "./isValidDate";
export { default as niceKeys } from "./niceKeys";

export { withinHours, minimizeTimeSpan } from "./timeHelper";
export { tabifyOrder } from "./tabifyOrder";
export { formatPickupAddress, formatDeliveryAddress } from "./formatAddress";

/**
 *
 * @param {Number} timestamp in seconds
 * @returns {boolean}
 */
const isTokenValid = (timestamp = "") => {
  if (!timestamp) return false;

  const currentInSeconds = dayjs().unix();
  return timestamp > currentInSeconds;
};

const getDateWithAddedTime = (date, time) => {
  if (dayjs(date).isValid()) {
    let timeArray = [];
    if (time) {
      timeArray = time.split(":");
    }

    const newDateString = dayjs(date)
      .set("hour", timeArray[0] || 0)
      .set("minute", timeArray[1] || 0)
      .set("second", timeArray[2] || 0)
      .format();
    return newDateString;
  }

  return "";
};

const getSortedOrdersWithProjectId = (ordersData, ProjectID) => {
  const projectOrders = ordersData?.filter(
    (item) => item?.ProjectID === parseInt(ProjectID)
  );

  const projectOrdersSorted = orderBy(
    projectOrders,
    ["OrderStatus.[0].PlanedDateFrom"],
    ["asc"]
  );

  return projectOrdersSorted;
};

const getInvoicesWithProjectId = (invoices, projectId) => {
  // get Invoices for projects projectId
  const projectInvoicesData = invoices?.filter((item) => {
    const { ProjectIDs } = item;

    if (ProjectIDs === null) return false;

    const projectIds = ProjectIDs.split("\r");

    const hasProjectId = projectIds
      .map((str) => parseInt(str))
      .includes(projectId);

    return hasProjectId;
  });

  return projectInvoicesData;
};

export {
  isTokenValid,
  getDateWithAddedTime,
  getSortedOrdersWithProjectId,
  getInvoicesWithProjectId
};
