//@ts-check

import mtabApi from "./mtabApi";

import { BASE_URL } from "../config";

/**
 *
 * @param {string} path
 * @returns
 */
const fileDownload = (path, service, filename) => {
  if (path) {
    return `${BASE_URL}/file?path=${path}${
      service ? "&service=" + service : ""
    }${filename ? "&filename=" + filename : ""}`;
  }
  return "";
};

/**
 *
 * @param {object} formData
 * @param {string} token
 * @returns
 */
const fileUpload = async (formData, token) => {
  try {
    const resp = await mtabApi({
      method: "post",
      url: "/upload",
      data: formData,
      headers: {
        Authorization: `token ${token}`,
        "Content-Type": "multipart/form-data"
      }
    });
    return resp;
  } catch (error) {
    return error;
  }
};

/**
 *
 * @param {object} data
 * @param {string} token
 * @returns
 */
const deleteFile = async (data, token) => {
  // need to clear custom data
  const { file, Url, columns, ...rest } = data;

  try {
    const resp = await mtabApi({
      method: "post",
      url: "/upload/update",
      data: rest,
      headers: {
        Authorization: `token ${token}`,
        "content-type": "application/json"
      }
    });

    return resp;
  } catch (error) {
    return error;
  }
};

export { fileDownload, fileUpload, deleteFile };
