const formatDeliveryAddress = (address) => {
  if (address) {
    if (address.DeliveryZipCode === null) {
      return {
        Address: `${address.DeliveryAdress}<br />${address.DeliveryCity}<br />${
          address.DeliveryCountry || "SVERIGE"
        }`
      };
    } else {
      return {
        Address: `${address.DeliveryAdress}<br />${address.DeliveryZipCode} ${
          address.DeliveryCity
        }<br />${address.DeliveryCountry || "SVERIGE"}`
      };
    }
  }
};

const formatPickupAddress = (address) => {
  if (address) {
    if (address.PickupZipCode === null) {
      return {
        Address: `${address.PickupAdress}<br />${address.PickupCity}}<br />${
          address.PickupCountry || "SVERIGE"
        }`
      };
    } else {
      return {
        Address: `${address.PickupAdress}<br />${address.PickupZipCode} ${
          address.PickupCity
        }<br />${address.PickupCountry || "SVERIGE"}`
      };
    }
  }
};

export { formatPickupAddress, formatDeliveryAddress };
