import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";

import { LoginForm, VerifyForm, Loader } from "../../components";
import { useApi } from "../../context/context";
import { useTranslation } from "react-i18next";

import "./style.scss";

function Login() {
  const { t } = useTranslation();
  const { user, isVerified, verifyUser } = useApi();

  const navigate = useNavigate();

  const Loading = () => (
    <div className={"login-container centered"}>
      <Typography variant="h1" color="primary.main">
        {t("welcome")}, {user?.FirstName || ""}!
      </Typography>
      <Loader sx={{ transform: "translateY(50%)", maxWidth: 500, m: "auto" }} />
    </div>
  );

  if (user?.CompanyID) {
    if (user?.token) {
      setTimeout(() => {
        navigate("/");
      }, 1500);

      return <Loading />;
    } else {
      return (
        <div className={"login-container centered"}>
          <Typography variant="h1" color="primary.main">
            {t("verifyYourAccount")}
          </Typography>
          <VerifyForm
            onSuccess={(result) => {
              verifyUser(result);
              navigate("/");
            }}
            onFail={() => console.error("failed.")}
            user={user}
          />
        </div>
      );
    }
  } else {
    return (
      <div className={"login-container centered"}>
        <Typography variant="h1" color="primary.main">
          {t("login")}
        </Typography>
        <LoginForm />
      </div>
    );
  }
}

export default Login;
