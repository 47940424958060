import { useEffect, useState } from "react";
import styles from "./FilterList.module.scss";

const FilterList = (props) => {
  const {
    filterControl,
    setFilterControl,
    opacity,
    searchValue,
    setSearchValue,
    resultLengths
  } = props;
  const [opacityClass, setOpacityClass] = useState();

  useEffect(() => {
    setOpacityClass(opacity);
  }, [opacity]);

  const toggleControl = (filter, i) => {
    filter.status = !filter.status;

    const updatedFilterList = [...filterControl];
    updatedFilterList[i] = filter;

    setFilterControl(updatedFilterList);
  };

  return (
    <ul className={`${styles.filterlist} ${styles[opacityClass]}`}>
      {filterControl?.map((filter, i) => {
        return (
          <li key={filter.label}>
            <button
              id={filter.id}
              className={filter.status ? styles.isActive : styles.notActive}
              onClick={(e) => toggleControl(filter, i)}
            >
              {filter.label} <strong>({resultLengths[i] || 0})</strong>
            </button>
          </li>
        );
      })}
      {searchValue && (
        <li key={"search-list-item"}>
          <button
            className={`${styles.isActive} ${styles.searchFilter}`}
            onClick={(e) => {
              setSearchValue("");
            }}
          >
            {searchValue}
          </button>
        </li>
      )}
    </ul>
  );
};

export default FilterList;
