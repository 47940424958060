import { useTranslation } from "react-i18next";
import { useApi } from "../../context/context";
import { ProjectsList } from "../../components";

import Typography from "@mui/material/Typography";

function Home() {
  const { projects } = useApi();
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h1" color="primary.main">
        {t("projects")}
      </Typography>
      <ProjectsList projects={projects} />
    </>
  );
}

export default Home;
