import { Route, Routes } from "react-router-dom";
// import { PrivateRoute } from "./components";

import {
  Login,
  Home,
  Invoices,
  Project,
  Order,
  Account,
  NotFound,
  SetPassword
} from "./pages";

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/profile" element={<Account />} />
    <Route path="/account/set-password" element={<SetPassword />} />
    <Route path="/account/set-password/:userId" element={<SetPassword />} />
    <Route path="/invoices" element={<Invoices />} />
    <Route path="/project/:projectId" element={<Project />} />
    <Route path="/order/:orderId" element={<Order />} />
    <Route path="/login" element={<Login />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default AppRoutes;
