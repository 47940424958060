const niceKeys = (key) => {
  let spaceKey = key.replace(/([A-Z])/g, " $1").trim();

  return capitalizeFirstLetter(spaceKey.replace(/I D/g, "ID"));
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default niceKeys;
