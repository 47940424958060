import { useApi } from "context/context";
// import { useTranslation } from "react-i18next";

import { DownloadCloud, Document } from "../../assets/icons";
import deleteIcon from "../../assets/images/delete-button.svg";
import { FileUpload } from "../";
import { fileDownload, deleteFile } from "../../api/file";

import styles from "./FileList.module.scss";

const FileList = (props) => {
  const {
    list,
    canUpload,
    project,
    order,
    orders,
    showNotes,
    updateFileList = () => {}
  } = props;

  const { docsLoading, user } = useApi();
  // const { t } = useTranslation();

  const listContainer = list?.map((item, i) => {
    // console.log("item.Status", item.Status);

    const {
      Id,
      file,
      columns,
      Url = "",
      src = "",
      FileNameOrg,
      CreatedByCIP_ID,
      Notes,
      Status
    } = item || {};

    if (Status === 2) {
      return null;
    }

    let canDelete = false;
    const userId = +user?.Id;

    if (userId === +CreatedByCIP_ID) {
      canDelete = true;
    }

    const downloadSrc = Url
      ? fileDownload(Url, "external", FileNameOrg)
      : fileDownload(src, null, FileNameOrg);

    const downloadName = FileNameOrg || "signature.pdf";

    return (
      <tr key={i}>
        <td className={styles.file}>
          <Document className={styles.document} />
          <a
            href={downloadSrc}
            download={downloadName}
            target="_blank"
            rel="noopener noreferrer"
          >
            {file}
          </a>
        </td>
        {columns &&
          Object.values(columns)?.map((item, index) => (
            <td key={index}>
              <p>{item}</p>
            </td>
          ))}
        {showNotes ? (
          <td>
            <p>{Notes}</p>
          </td>
        ) : (
          ""
        )}
        <td>
          <a
            href={downloadSrc}
            download={downloadName}
            target="_blank"
            rel="noopener noreferrer"
          >
            <DownloadCloud className={styles.downloadCloud} />
          </a>
          {canDelete ? (
            <img
              className="delete-file-icon"
              src={deleteIcon}
              alt="Delete"
              width={16}
              onClick={async (e) => {
                e.preventDefault();
                const promptReply = window.confirm(
                  "Are you sure you want to delete the file?"
                );
                if (promptReply) {
                  await deleteFile(item, user?.token);
                  updateFileList();
                }
              }}
            />
          ) : null}
        </td>
      </tr>
    );
  });

  const emptyList = (
    <tr key="empty">
      <td colSpan={3}>
        <div className={styles.file}>
          <Document className={styles.document} /> No documents found
        </div>
      </td>
    </tr>
  );

  return (
    <div className={styles.fileListContainer}>
      <table className="order-events__container">
        <thead>
          <tr>
            <th scope="col">FILE</th>
            <th scope="col">TYPE</th>
            <th scope="col">ORDER</th>
            {showNotes ? <th scope="col">NOTE</th> : ""}
            <th scope="col">DOWNLOAD</th>
          </tr>
        </thead>
        <tbody>
          {docsLoading ? (
            <tr key="loading">
              <td colSpan={3}>"Loading..." </td>
            </tr>
          ) : listContainer?.length > 0 ? (
            listContainer
          ) : (
            emptyList
          )}
        </tbody>
      </table>

      {canUpload && (
        <FileUpload
          project={project}
          order={order}
          orders={orders}
          updateFileList={updateFileList}
        />
      )}
    </div>
  );
};

export default FileList;
