import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Typography from "@mui/material/Typography";

import { DownloadCloud, Document } from "assets/icons";
import { useApi } from "context/context";
import { FileList, OrderCard, OrderHeaderCard, TableList } from "components";
import {
  getOrderDates,
  getOrderType,
  getOrderStatus,
  cipDateFormat
} from "utils";
import { fileDownload } from "api/file";
import { filesByProjectId } from "api";
import styles from "components/Layout/Layout.module.scss";
import { useTranslation } from "react-i18next";

function Project() {
  // console.count("Project Render count");
  const { t } = useTranslation();
  const { projectId } = useParams();

  const { getProjectByProjectId, documents, user } = useApi();

  const [project, setProject] = useState(getProjectByProjectId(projectId));
  const [projectDocuments, setProjectDocuments] = useState([]);

  console.log("project", project);

  useEffect(() => {
    if (projectId) {
      setProject(getProjectByProjectId(projectId));
    }
  }, [getProjectByProjectId, projectId]);

  const fetchDocuments = async (orders) => {
    const documentList = await documents(orders, projectId);
    const filesList = await filesByProjectId(projectId, user?.token);

    const modifiedDocumentsList = documentList.map((file) => {
      return {
        ...file,
        file: file.FullName,
        Url: `Transport_pod/pod-${file.OrderID}.pdf`,
        columns: {
          type: "Signature",
          order: file.OrderID > 0 ? file.OrderID : ""
        }
      };
    });

    const modifiedfilesList = filesList.map((file) => {
      return {
        ...file,
        file: file.FileNameOrg,
        Url: `Files/${file.FilenameSaved}`,
        columns: {
          type: file.Type,
          order: file.OrderID > 0 ? file.OrderID : ""
        }
      };
    });

    setProjectDocuments([...modifiedDocumentsList, ...modifiedfilesList]);
  };

  useEffect(() => {
    if (project) {
      const { orders } = project;
      // async func
      const fetchDocuments = async () => {
        const documentList = await documents(orders, projectId);
        const filesList = await filesByProjectId(projectId, user?.token);

        const modifiedDocumentsList = documentList.map((file) => {
          return {
            ...file,
            file: file.FullName,
            Url: `Transport_pod/pod-${file.OrderID}.pdf`,
            columns: {
              type: "Signature",
              order: file.OrderID > 0 ? file.OrderID : ""
            }
          };
        });
        const modifiedfilesList = filesList.map((file) => {
          return {
            ...file,
            file: file.FileNameOrg,
            Url: `Files/${file.FilenameSaved}`,
            columns: {
              type: file.Type,
              order: file.OrderID > 0 ? file.OrderID : ""
            }
          };
        });
        setProjectDocuments([...modifiedDocumentsList, ...modifiedfilesList]);
      };

      // run
      fetchDocuments();
    }
  }, [documents, project, projectId]);

  if (!project) {
    return (
      <>
        <h1>No projects found</h1>
      </>
    );
  }

  const { orders, Header, ProjectID, invoices } = project;
  // console.log("orders", orders);
  // console.log("docs", projectDocuments);
  return (
    <>
      <Link className="back-button" to="/">
        {t("back")}
      </Link>
      <span className="sub-heading">
        {t("project")} {projectId}
      </span>
      <Typography variant="h1" color="primary.main">
        {Header || ProjectID}
      </Typography>
      <ul>
        <li>
          <OrderHeaderCard />
        </li>
        {orders
          ?.filter((a) => a?.OrderStatus[0]?.StatusNo !== 0)
          .map((order, i) => {
            // TODO: Above filter is QUICK FIX to remove orders with statusno == 0
            // console.log("single order", order);
            const {
              Header,
              OrderID,
              DeliveryAdress,
              DeliveryCity,
              PickupAdress,
              PickupCity,
              OrderStatus = [{}] //  [{}] array of status objects
            } = order;

            const { startDate, endDate, pickupTime, deliveryTime } =
              getOrderDates(order);

            const firstOrder =
              OrderStatus && OrderStatus.length > 0 ? OrderStatus[0] : {};

            const {
              PickupWantedTimeFlag = null,
              DeliveryWantedTimeFlag = null
            } = firstOrder;
            // console.log("order", order);
            // console.log("order.OrderStatus", order.OrderStatus);
            // console.log(OrderID, order);
            // const fakeOrder = {
            //   OrderStatus: [{ StatusNo: 319 }]
            // };
            return (
              <li key={order.OrderID}>
                <Link to={`/order/${OrderID}`}>
                  <OrderCard
                    Header={Header}
                    OrderStatus={OrderStatus}
                    ongoingOrder={getOrderStatus(order)}
                    orderId={OrderID}
                    to={{
                      address: DeliveryAdress,
                      city: DeliveryCity
                    }}
                    from={{
                      address: PickupAdress,
                      city: PickupCity
                    }}
                    orderType={getOrderType(order)}
                    startDate={startDate}
                    endDate={endDate}
                    pickupTime={pickupTime}
                    deliveryTime={deliveryTime}
                    PickupWantedTimeFlag={PickupWantedTimeFlag}
                    DeliveryWantedTimeFlag={DeliveryWantedTimeFlag}
                    first={i < 1}
                    last={i === orders?.length - 1}
                  />
                </Link>
              </li>
            );
          })}
      </ul>
      <div className={styles.docListContainer}>
        <div>
          <h2>{t("documents")}</h2>
          <FileList
            project={project}
            orders={orders}
            list={projectDocuments}
            updateFileList={() => {
              console.debug("running callback");
              fetchDocuments(orders);
            }}
            canUpload={true}
          />
        </div>
        <div>
          <h2>{t("invoices")}</h2>
          <TableList
            data={invoices}
            canUpload={false}
            columns={[
              {
                name: "InvoiceNumber",
                title: "#",
                modifier: (v) => (
                  <>
                    <span className={"td-icon"}>
                      <Document />
                    </span>{" "}
                    {v}
                  </>
                )
              },
              {
                name: "InvoiceDate",
                title: t("date"),
                modifier: cipDateFormat
              },
              {
                name: "Description",
                title: t("description"),
                modifier: (v) => <strong>{v}</strong>
              },
              {
                name: "InvoiceNumber",
                title: "",
                modifier: (v) => (
                  <a
                    target="_blank"
                    download
                    href={fileDownload(
                      "Invoices/invoice-" + v + ".pdf",
                      "external",
                      "invoice-" + v + ".pdf"
                    )}
                    style={{ paddingRight: "10px" }}
                    rel="noreferrer"
                  >
                    <DownloadCloud />
                  </a>
                )
              }
            ]}
          />
        </div>
      </div>
    </>
  );
}

export default Project;
