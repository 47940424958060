const addMeasureUnit = (key = "", value = "") => {
  if (key === "PackageVolume") {
    return `${value} m³`;
  }

  if (key === "PackageVolumeWeight") {
    return `${value} kg/m³`;
  }

  if (key === "PackageWeight") {
    return `${value} kg`;
  }

  if (key === "PackageFlakMeter") {
    return `${value} flm`;
  }

  if (value === null) {
    return null;
  }

  if (value === 0) {
    return 0;
  }
};

export default addMeasureUnit;
