import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { LoadScript } from "@react-google-maps/api";

import "./i18n";
import "./index.scss";
import App from "./App/App";

import ApiContextProvider from "./context/context";
import LocalContextProvider from "./context/LocalContext";
import { GOOGLE_MAPS_API_KEY } from "./config";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
      <BrowserRouter>
        <LocalContextProvider>
          <ApiContextProvider>
            <App />
          </ApiContextProvider>
        </LocalContextProvider>
      </BrowserRouter>
    </LoadScript>
  </React.StrictMode>
);
